import format from 'date-fns/format';

export const formatTime = (seconds) => {
  var date = new Date(null);
  date.setSeconds(seconds);
  var timeString = date.toISOString().substr(14, 5);
  return timeString;
};

export const getTimeRemaining = (endtime) => {
  var now = new Date();
  var offset = now.getTimezoneOffset() * 60000;
  var nowUTC = new Date(now.getTime() + offset);

  var t = Date.parse(endtime) - Date.parse(nowUTC);
  var seconds = Math.floor((t / 1000) % 60);
  var minutes = Math.floor((t / 1000 / 60) % 60);
  var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  var days = Math.floor(t / (1000 * 60 * 60 * 24));
  return {
    total: t,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

export const dateFromTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const string = `${date.getDate()} ${date.getMonth()} ${date.getFullYear()}`;
  // return date.toDateString();
  return format(date, 'd MMM yyyy');
};

export const formatMoney = (amount, currency) => {
  const options = {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: 2,
  };
  // if its a whole, dollar amount, leave off the .00
  // if (amount % 100 === 0) options.minimumFractionDigits = 0;
  const formatter = new Intl.NumberFormat('en-US', options);
  const output = formatter.format(amount / 100);
  const string = `${output} ${options.currency}`;
  return string;
};

export const capCheck = (user, caps) => {
  if (!user) return false;
  if (!user.capabilities) return false;
  const hasCap = caps.some((cap) => user.capabilities.includes(cap));
  return hasCap;
};

export const preciseRound = (num, dec) => {
  if (typeof num !== 'number' || typeof dec !== 'number') return false;

  var num_sign = num >= 0 ? 1 : -1;

  return parseFloat((Math.round(num * Math.pow(10, dec) + num_sign * 0.0001) / Math.pow(10, dec)).toFixed(dec));
};

export const parseDuration = (iso8601Duration) => {
  if (!iso8601Duration) return null;

  const regex = /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/;

  const matches = iso8601Duration.match(regex);

  return {
    sign: matches[1] === undefined ? '+' : '-',
    years: matches[2] === undefined ? 0 : parseInt(matches[2]),
    months: matches[3] === undefined ? 0 : parseInt(matches[3]),
    weeks: matches[4] === undefined ? 0 : parseInt(matches[4]),
    days: matches[5] === undefined ? 0 : parseInt(matches[5]),
    hours: matches[6] === undefined ? 0 : parseInt(matches[6]),
    minutes: matches[7] === undefined ? 0 : parseInt(matches[7]),
    seconds: matches[8] === undefined ? 0 : parseInt(matches[8]),
  };
};

export const parseDurationSecs = (iso8601Duration) => {
  const d = parseDuration(iso8601Duration);
  if (!d) return 0;

  const hSecs = d.hours * 60 * 60;
  const mSecs = d.minutes * 60;
  const totalSecs = hSecs + mSecs + d.seconds;
  return totalSecs;
};

export const secsToRoughMins = (s) => {
  var minutes = Math.floor(s / 60);
  var seconds = s - minutes * 60;
  if (seconds > 30) {
    minutes += 1;
  }
  return minutes + ' mins';
};

export const parseDurationString = (duration) => {
  const d = parseDuration(duration);
  if (!d) return '';

  var durationString = str_pad_left(d.minutes, '0', 2) + ':' + str_pad_left(d.seconds, '0', 2);
  return durationString;
};

function str_pad_left(string, pad, length) {
  return (new Array(length + 1).join(pad) + string).slice(-length);
}
